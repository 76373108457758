import get from 'lodash/get';
import flatten from 'lodash/flatten';

import { APP_PERMISSIONS } from './constants';

export const getUserGroupId = (user) => {
    return get(user, 'group._id');
};

export const getAllWingmanPermissions = () => {
    return flatten(Object.values(APP_PERMISSIONS));
};

// check if the user has the permission to access any of the wingman apps
export const isWingmanUser = (user) => {
    const allPermissions = getAllWingmanPermissions();

    const userGroupId = getUserGroupId(user);

    return userGroupId && allPermissions.includes(userGroupId);
};

export const isWingmanRole = (user, role) => {
    const userGroupId = getUserGroupId(user);

    return (
        userGroupId &&
        APP_PERMISSIONS[role] &&
        APP_PERMISSIONS[role].includes(userGroupId)
    );
};
